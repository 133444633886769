import { defineMessages } from 'react-intl';

export default defineMessages({
  marriottHeroHeader: {
    id: 'rewards.marriott.marriottHeroHeader',
    defaultMessage: 'Get more of what you love',
    description: 'Marriott homepage hero header',
  },
  marriottHeroBody: {
    id: 'rewards.marriott.marriottHeroBody',
    defaultMessage:
      'When you link your { starbucksRewardsTrademark } and Marriott Bonvoy® accounts, you’ll be on the way to earning more points, Stars and exciting experiences.{superscriptOne} ',
    description: 'Marriott homepage hero body message',
  },
  marriottHeroCta: {
    id: 'rewards.marriott.marriottHeroCta',
    defaultMessage: 'Join and Link',
    description: 'Marriott homepage hero cta',
  },
  marriottPerksHeader: {
    id: 'rewards.marriott.marriottPerksHeader',
    defaultMessage: 'Linked accounts earn even more{termsPerksReference1}',
    description: 'Marriott homepage perks header',
  },
  marriottPerksBlock1Header: {
    id: 'rewards.marriott.marriottPerksBlock1Header',
    defaultMessage: 'Earn 100 Marriott Bonvoy® points',
    description: 'Marriott homepage perks block 1 header',
  },
  marriottPerksBlock1Body: {
    id: 'rewards.marriott.marriottPerksBlock1Body',
    defaultMessage:
      'When you make three qualifying purchases at Starbucks during any Marriott Bonvoy® Week.{termsPerksReference2}',
    description: 'Marriott homepage perks block 1 body message',
  },
  marriottPerksBlock2Header: {
    id: 'rewards.marriott.marriottPerksBlock2Header',
    defaultMessage: 'Earn double Stars at Starbucks',
    description: 'Marriott homepage perks block 2 header',
  },
  marriottPerksBlock2Body: {
    id: 'rewards.marriott.marriottPerksBlock2Body',
    defaultMessage:
      'On days of eligible stays at hotels participating in Marriott Bonvoy®.{termsPerksReference3}',
    description: 'Marriott homepage perks block 2 body message',
  },
  marriottPerksBlock3Header: {
    id: 'rewards.marriott.marriottPerksBlock3Header',
    defaultMessage: 'Unlock exclusive experiences',
    description: 'Marriott homepage perks block 3 header',
  },
  marriottPerksBlock3Body: {
    id: 'rewards.marriott.marriottPerksBlock3Body',
    defaultMessage: 'For access to unforgettable and exciting events.',
    description: 'Marriott homepage perks block 3 body message',
  },
  marriottGettingStartedHeader: {
    id: 'rewards.marriott.marriottGettingStartedHeader',
    defaultMessage: 'Getting started',
    description: 'Marriott homepage getting started header',
  },
  marriottGettingStartedBody: {
    id: 'rewards.marriott.marriottGettingStartedBody',
    defaultMessage:
      'Use these three simple steps to link your {starbucksRewardsTrademark} and Marriott Bonvoy® accounts. By linking, you agree to the program terms and conditions. U.S. only.{superscriptOne}',
    description: 'Marriott homepage getting started body message',
  },
  marriottGettingStartedBlock1Header: {
    id: 'rewards.marriott.marriottGettingStartedBlock1Header',
    defaultMessage: 'Join Marriott Bonvoy®',
    description: 'Marriott homepage getting started block 1 header',
  },
  marriottGettingStartedBlock1Body: {
    id: 'rewards.marriott.marriottGettingStartedBlock1Body',
    defaultMessage:
      '<cta>Join now</cta> then come back here to continue. Already a member? Skip to step 2.',
    description: 'Marriott homepage getting started block 1 body message',
  },
  marriottGettingStartedBlock2Body: {
    id: 'rewards.marriott.marriottGettingStartedBlock2Body',
    defaultMessage:
      '<ctaJoin>Join now</ctaJoin> or <ctaSignIn>Sign in</ctaSignIn> to begin the account linking process.',
    description: 'Marriott homepage getting started block 2 body message',
  },
  marriottGettingStartedBlock3Header: {
    id: 'rewards.marriott.marriottGettingStartedBlock3Header',
    defaultMessage: 'Link your accounts',
    description: 'Marriott homepage getting started block 3 header',
  },
  marriottGettingStartedBlock3Body: {
    id: 'rewards.marriott.marriottGettingStartedBlock3Body',
    defaultMessage:
      '<ctaLink>Finish linking</ctaLink>! Already linked? Check out our <partnershipFAQs>FAQs</partnershipFAQs> for benefit details.',
    description: 'Marriott homepage getting started block 3 body message',
  },
  redirectHeading: {
    id: 'rewards.marriott.redirect.heading',
    defaultMessage: 'Almost done!',
    description: 'Heading for redirect page',
  },
  redirectBody: {
    id: 'rewards.marriott.redirect.body',
    defaultMessage: "We're handing you off to finish linking your accounts.",
    description: 'Body for redirect page',
  },
});
